/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { Box, Flex, Heading, Text } from "rebass"
import { Header } from "../components/header"
import { Footer } from "../components/footer"
import { Container } from "../components/container"
import mel from "../images/mel2.jpg"
import { Helmet } from "react-helmet"

const Img = styled.img`
  max-width: 350px;
  height: auto;
  margin: 0;
  margin-left: auto;
`

export default () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Melanie Couper Physiotherapy | About</title>
      </Helmet>
      <Header />
      <Container>
        <div className="aboutWrapper">
          <div className="container">
            <Flex mb={6} flexDirection={["column", "column", "row"]}>
              <Box flexBasis="50%" pr={2} color="textGrey" mb={[4, 4, 0]}>
                <Heading
                  color="darkGrey"
                  fontFamily="body"
                  mb={3}
                  fontSize={5}
                  fontWeight={300}
                >
                  About Melanie Couper
                </Heading>
                <Heading
                  mb={3}
                  as="h3"
                  fontSize={3}
                  fontFamily="body"
                  color="textGrey"
                  fontWeight="lessBold"
                >
                  Physiotherapist. B.App.Sc (Physiotherapy)
                </Heading>
                <Text fontFamily="body" fontSize={1}>
                  Mel Couper is committed to empowering people to journey well
                  in their body during all phases of life.
                  <br />
                  <br />
                  Mel’s work is particularly influenced by her training &
                  experience in Pilates, Feldenkrais, Horse-assisted
                  physiotherapy (Hippotherapy) & Sensorimotor Psychotherapy
                  (Trauma) in which she holds a Certificate I from the
                  Australian Childhood Foundation. Her work has also been
                  strongly influenced by parenthood, which led to her interest
                  and training in pelvic health physiotherapy.
                  <br />
                  <br />
                  Mel has been working as a private physiotherapist in Dorrigo &
                  the Bellingen Shire since 2014. She currently works from rooms
                  at Dorrigo Health & Wellbeing (Cork Trust Medical Centre,
                  Dorrigo), Three Rivers Health Bellingen, Coffs Harbour Women’s
                  Health Centre and from Summervilles Rd. in Thora. She is
                  Medicare and Private Health Insurance registered.
                </Text>
              </Box>
              <Flex
                flexBasis="50%"
                flexDirection="column"
                justifyContent="center"
              >
                <Img src={mel} alt="mel couper" />
              </Flex>
            </Flex>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  )
}
